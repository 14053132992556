import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1/9, 4) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'MORRALLA',
    lpAddresses: {
      97: '',
      56: '0xadcBAEbaac0f775923eBDFb6B904399DA8801275',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'MORRALLA-BNB',
    lpAddresses: {
      97: '',
      56: '0xe54b765983fc0a2e15943a5d9fc8b1af7bbc9aa6',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'MORRALLA-BUSD PCS',
    lpAddresses: {
      97: '',
      56: '0xa36c4c7d11732681e58cbb7b8716acb8871ec4c3',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.busd,
  },
  // NO MORE DEX!!! below old farms
  {
    pid: 9,
    lpSymbol: 'MORRALLA-BNB',
    lpAddresses: {
      97: '',
      56: '0x7D30522145e302841387049B7ba857BD8ad30548',
    },
    isCommunity: true,
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x78d73de9b90cd298a7ab38cb95f756e786a7785c',
    },
    isCommunity: true,
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 15,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0xa6550183b5a781Af21955d47C883486Ead3511bB',
    },
    isCommunity: true,
    token: serializedTokens.btcb,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 16,
    lpSymbol: 'ETH-BTCB',
    lpAddresses: {
      97: '',
      56: '0x901C7b78386734EC609CfAC5cE40E516605e4ce2',
    },
    isCommunity: true,
    token: serializedTokens.eth,
    quoteToken: serializedTokens.btcb,
  },
  {
    pid: 17,
    lpSymbol: 'SHIB-BNB',
    lpAddresses: {
      97: '',
      56: '0x2AC17EbC787f2AC7E9EB2433C60F29d92B8a81a0',
    },
    isCommunity: true,
    token: serializedTokens.shib,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 18,
    lpSymbol: 'ADA-BNB',
    lpAddresses: {
      97: '',
      56: '0x3fe44f90927724AbeA4dc5db9e2C7AB8480D42a5',
    },
    isCommunity: true,
    token: serializedTokens.ada,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 19,
    lpSymbol: 'GLCH-BNB',
    lpAddresses: {
      56: '0xAFdaB8CeEA648ad36875a946F10656E93305ef27',
    },
    isCommunity: true,
    token: serializedTokens.glitch,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 20,
    lpSymbol: 'AVAX-BNB',
    lpAddresses: {
      56: '0x63fd6c5A32F44B42f397860932b280dd3C98fbf5',
    },
    isCommunity: true,
    token: serializedTokens.avax,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 21,
    lpSymbol: 'FTM-BNB',
    lpAddresses: {
      56: '0x4868B9986d4504E5bd50F32Eb22BE5516c958bC1',
    },
    isCommunity: true,
    token: serializedTokens.ftm,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 22,
    lpSymbol: 'SOL-BUSD',
    lpAddresses: {
      56: '0x2ad6c17a1DB3e8Eb172E4A74fEc9106C93a4771A',
    },
    isCommunity: true,
    token: serializedTokens.sol,
    quoteToken: serializedTokens.busd,
  },
  // cero pools abajo
  {
    pid: 10,
    lpSymbol: 'MORRALLA-BUSD',
    lpAddresses: {
      97: '',
      56: '0xdac7473e6bcdc6aa5efcc7d33875963fae6fdb9f',
    },
    isCommunity: true,
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 11,
    lpSymbol: 'MORRALLA-ETH',
    lpAddresses: {
      97: '',
      56: '0xdda72a255255c248a22aafdfe481a8b9cf2f1ea1',
    },
    isCommunity: true,
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.eth,
  },
  {
    pid: 13,
    lpSymbol: 'MORRALLA-CAKE',
    lpAddresses: {
      97: '',
      56: '0x3E6189c4d77210a72Bb8976DA653Ba8c7C676a78',
    },
    isCommunity: true,
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 14,
    lpSymbol: 'MORRALLA-BTC',
    lpAddresses: {
      97: '',
      56: '0xdec88Ad62d4EaC529514E8E2cf35822953Dda7f6',
    },
    isCommunity: true,
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.btcb,
  },

  /** todo abajo no usar debe de ser todo PCS
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  {
    pid: 3,
    lpSymbol: 'MORRALLA-XRP CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x3140efe2f611c8391e39e3179e50c59a74731371',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.xrp,
  },
  // pcs lp para valor en usd tianguis v1-2
  {
    pid: 4,
    lpSymbol: 'BNB-BUSD CAKE CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'MORRALLA-ETH CAKE LP',
    lpAddresses: {
      97: '',
      56: '0xAA4C6f6d60F1E52eA9dF8dcE98860eF6E5FC1f36',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.eth,
  },
  {
    pid: 6,
    lpSymbol: 'MORRALLA-BTC CAKE LP',
    lpAddresses: {
      97: '',
      56: '0xb1827e26d37a04b6b73851dc0a891ece8e414c42',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.btcb,
  },
  {
    pid: 7,
    lpSymbol: 'MORRALLA-ADA CAKE LP',
    lpAddresses: {
      97: '',
      56: '0xeb8967533572027a337961690adc3e5fb2f9fccc',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.ada,
  },
  {
    pid: 8,
    lpSymbol: 'MORRALLA-CAKE CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x24E2570953108F56C36A7EE66d70ce740Cfd218e',
    },
    token: serializedTokens.morralla,
    quoteToken: serializedTokens.cake,
  },
]

export default farms
